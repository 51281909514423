import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import PropTypes from "prop-types";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "gatsby";
import Image from "./Image";

const AluminievaDograma = ({ frontmatter }) => {
  return (
    <div className="container d-flex flex-wrap pt-5 pt-lg-6">
      <h3 className="col-12 pb-2">{frontmatter.header}</h3>
      <div className="d-flex justify-content-lg-start justify-content-center flex-column col-12 px-md-0 px-2 col-lg-6 px-0">
        <Carousel
          variant="dark"
          className="col-md-8 col-lg-12 align-self-center flex-md-shrink-1"
          indicators={false}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} size="lg" color="#212529" />}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" color="#212529" />}
        >
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="al_dograma/1.jpg"
              alt="Алуминиева дограма"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="al_dograma/3.jpg"
              alt="Алуминиева дограма"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="al_dograma/5.jpg"
              alt="Алуминиева дограма"
              style={{ maxHeight: "300px" }}
            />
          </Carousel.Item>
        </Carousel>
        <div className="d-flex justify-content-center my-3">
          <Link
            className="btn btn-primary"
            to="/gallery/#aluminieva-dograma"
            activeClassName="active"
          >
            Към Галерията
          </Link>
        </div>
      </div>
      <section className="col-lg-6">
        <b>Предимства</b>
        <ul>
          <li>
            Дълги години запазва качествата си, тъй като неговата структура е неподатлива на
            големите температурни амплитуди по нашите географски ширини.
          </li>
          <li>Запазва външния си вид във времето.</li>
          <li>Алумиевата дограмата се нуждае от минимална поддръжка - единствено почистване.</li>
          <li>
            Здравината на алуминия позволява свобода в избора на решения за вашия дом -{" "}
            <b>остъклени тераси, вътрешни врати, фасади, зимни градини и много други.</b>
          </li>
        </ul>
      </section>
      <section className="col-12">
        <b>Видове</b>
        <ul>
          <li>Широка цветова гама на профилите</li>
          <li>Висококачествени профили</li>
          <li>Богат избор на стъклопакети (различни цветови отенъци, четири сезона и др.)</li>
          <li>Към вашите врати и прозорци предлагаме различни модели комарници.</li>
        </ul>
      </section>
    </div>
  );
};

AluminievaDograma.propTypes = {
  frontmatter: PropTypes.object,
};

AluminievaDograma.defaultProps = {
  frontmatter: {},
};

export default AluminievaDograma;
